import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function ProcessGuideContentHDBRental() {
  return (
    <React.Fragment>
      <Typography variant="body">
        Below guide breaks down your transaction journey into stages and tells
        you what to expect in each stage.
      </Typography>
      <br />
      <br />
      <Typography variant="h6">1. Listing Live</Typography>
      <Typography variant="body">
        Our team has already taken photos of your unit, prepared a listing and
        published it on property portals and social media networks. We will now
        communicate with eager tenants and arrange viewings for interested
        prospects.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Days to Months depending on demand for your unit
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Be as flexible with your schedule as possible to help conduct as
            many viewings as possible.
          </li>
          <li>Maintain a clean and presentable unit to wow tenants.</li>
          <li>
            Keep checking the customer portal for feedback from tenants, monitor
            demand for your unit and the competition.
          </li>
          <li>
            By now, with Bluenest teams help you should have already checked you
            eligibility to rent out your hdb unit by checking for Minimum
            Occupation Period (MOP), your citizenship status and size of your
            unit and number of occupants.
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Our marketing team is using our AI powered tools to monitor
            performance of your listing as well as the competition on various
            platforms and optimizing to ensure you stay ahead of the
            competition.
          </li>
          <li>
            Our top agents are responding to prospects and putting their
            experience to work to help you get the best possible price.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h6">2. Letter of Intent (LOI)</Typography>
      <Typography variant="body">
        Congratulations! We have found a tenant and agreed on a price. We need
        to ensure the tenant is eligible to rent your unit. We will now receive
        a Letter of Intent from the tenant and collect a Good Faith Deposit
        (optional). Since the tenancy agreement is drafted based on LOI, the LOI
        should include Price, Security Deposit, Air-con usage, etc.
        <br />
        <br />
        The Good Faith Deposit is usually 1 month of rent and is used to pay the
        first month of rent or converted into a Security Deposit upon signing of
        the tenancy agreement. If the prospective tenant explicitly backs out on
        the decision to rent after signing the Letter of Intent, you will be
        able to keep the full Good Faith Deposit. If you back out about renting
        to the tenant, then you have to return the full Good Faith Deposit to
        the tenant.
        <br />
        <br />
        Once the LOI has been signed, we will no longer advertise your unit.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Tenancy agreement is typically prepared in about 3 days after(or as
        stated in LOI) LOI signing.
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">3. HDB Application &amp; Approval</Typography>
      <Typography variant="body">
        A formal application needs to be made via HDB portal with full tenant
        details.
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">4. Tenancy Agreement </Typography>
      <Typography variant="body">
        Tenancy Agreement is a legal document contract between a landlord and
        tenant and includes the landlords particulars, tenants particulars and
        lease details. A Tenancy Agreement is provided by the landlord to the
        tenant.
        <br />
        <br />
        Good Faith Deposit given during LOI could be converted to a security
        deposit or a security deposited could be collected depending on terms in
        the Tenancy Agreement.
      </Typography>

      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">5. Tenancy Agreement Stamping</Typography>
      <Typography variant="body">
        Within 14 days of signing the Tenancy Agreement, it must be stamped. As
        part of this, a rental stamp duty tax is paid which is equivalent to
        0.4% of the total rent across the entire lease period. This expense is
        borne by the tenant and is paid by logging on to the IRAS website or
        taking the Tenancy Agreement to any service bureau.
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">6. Inspection &amp; Handover</Typography>
      <Typography variant="body">
        Once the unit is ready for moving in, the tenant should be given an
        opportunity to inspect the unit. An inventory list detailing all the
        furnishings, fittings and appliances and their working conditions should
        be prepared to facilitate this process.
      </Typography>
      <br />
      <br />
    </React.Fragment>
  );
}

export default ProcessGuideContentHDBRental;
