import axios from "axios";
import { BASE_URL, FETCH_SUCCESS, FETCH_ERROR } from "../constants/Constants";

const Api = axios.create({
  baseURL: BASE_URL,
});

export const postApi = async (funcName, params) => {
  var resp = { status: FETCH_SUCCESS, errorText: null, data: null };
  try {
    const response = await Api.post(funcName, params);
    if (response.status != 200) {
      resp = {
        status: FETCH_ERROR,
        errorText: "Unexpected network error has occured",
        data: null,
      };
    } else {
      if (response.data.status != FETCH_SUCCESS) {
        resp = {
          status: response.data.status,
          errorText:
            response.data.statusDetail == ""
              ? "We were unable to process your request"
              : response.data.statusDetail,
          data: null,
        };
      } else {
        resp.data = response.data.response;
      }
    }
  } catch (e) {
    console.log(e);
    resp = {
      status: FETCH_ERROR,
      errorText: "Unexpected error has occured",
      data: null,
    };
  }
  return resp;
};
