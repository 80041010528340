import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import analytics from "../src/utils/analytics";
import Home from "../src/screens/Home";
import Error404 from "../src/screens/Error404";

function Switches() {
  analytics.sendCurrentPageview();
  return (
    <Switch>
      <Route exact path="/:listingId" component={Home} />
      <Route component={Error404} />
    </Switch>
  );
}

function App() {
  analytics.init();
  return (
    <BrowserRouter>
      <Switches />
    </BrowserRouter>
  );
}

export default App;
