import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";

import Title from "./Title";
import { FETCH_SUCCESS } from "../../constants/Constants";
import { postApi } from "../../utils/api";

const useStyles = (theme) => ({
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  rowContainer: {
    display: "flex",
  },
  cellKey: {},
  cellValue: {
    paddingLeft: 10,
  },
});

class CompetitorSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      competitionData: [],
    };
  }
  setError(errorMsg) {
    this.setState({
      errorMessage: errorMsg,
      loading: false,
      competitionData: [],
    });
  }
  handleData(data) {
    //console.log(data);
    this.setState({
      competitionData: data,
      loading: false,
    });
  }
  async fetchCompetition(listId) {
    try {
      const resp = await postApi("getCompetitionSummary", {
        listingId: listId,
      });
      if (resp.status == FETCH_SUCCESS) {
        this.handleData(resp.data);
      } else {
        this.setError(
          resp != null && resp.errorText != null && resp.errorText != ""
            ? resp.errorText
            : "Unable to process request"
        );
      }
    } catch (ex) {
      this.setError("Unable to process request");
    }
    const { onLoaded } = this.props;
    onLoaded();
  }
  componentDidMount() {
    const { listingId } = this.props;
    this.fetchCompetition(listingId);
  }
  render() {
    const { classes } = this.props;
    const { loading, errorMessage, competitionData } = this.state;
    return (
      <React.Fragment>
        <Title>Competition Summary</Title>
        {errorMessage ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        ) : loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.listContainer}>
            {competitionData != null && competitionData.number > 0 ? (
              <div>
                <div className={classes.rowContainer}>
                  <div className={classes.cellKey}>
                    <Typography component="h5" variant="subtitle1">
                      # of Competitors:
                    </Typography>
                  </div>
                  <div className={classes.cellValue}>
                    <Typography component="h5" variant="subtitle1">
                      <b>{competitionData.number}</b>
                    </Typography>
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.cellKey}>
                    <Typography component="h5" variant="subtitle1">
                      Avg Price of Competitors:
                    </Typography>
                  </div>
                  <div className={classes.cellValue}>
                    <Typography component="h5" variant="subtitle1">
                      <b>
                        $
                        {competitionData.avgPrice.toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                      </b>
                    </Typography>
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.cellKey}>
                    <Typography component="h5" variant="subtitle1">
                      Avg Area of Competitors:
                    </Typography>
                  </div>
                  <div className={classes.cellValue}>
                    <Typography component="h5" variant="subtitle1">
                      <b>{competitionData.avgArea} sqft</b>
                    </Typography>
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.cellKey}>
                    <Typography component="h5" variant="subtitle1">
                      Avg PSF of Competitors:
                    </Typography>
                  </div>
                  <div className={classes.cellValue}>
                    <Typography component="h5" variant="subtitle1">
                      <b>
                        {competitionData.avgPSF.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{" "}
                        $/sqft
                      </b>
                    </Typography>
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div className={classes.cellKey}>
                    <Typography component="h5" variant="subtitle1">
                      Avg Days on Market:
                    </Typography>
                  </div>
                  <div className={classes.cellValue}>
                    <Typography component="h5" variant="subtitle1">
                      <b>{competitionData.avgActiveDays} days</b>
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <Alert severity="info" icon={false}>
                Information about listings that compete with yours is not
                currently available. We are working on it!
              </Alert>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
CompetitorSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  listingId: PropTypes.string,
  onLoaded: PropTypes.func,
};
export default withStyles(useStyles)(CompetitorSummary);
