import React from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const TRACKING_ID = "UA-139174653-3";

function init() {
  React.useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(TRACKING_ID);
    }
  }, []);
}

async function sendEvent(categoryName, eventName, labelName, value) {
  if (!window.location.href.includes("localhost")) {
    ReactGA.event({
      category: categoryName, // Required
      action: eventName, // Required
      label: labelName,
      value: value,
      nonInteraction: false,
    });
  }
}

function sendCurrentPageview() {
  const location = useLocation();

  React.useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      const currentPath = location.pathname + location.search;
      ReactGA.set({ page: currentPath });
      ReactGA.pageview(currentPath);
    }
  }, [location]);
}

export default {
  init,
  sendEvent,
  sendCurrentPageview,
};
