import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Text,
  //Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Title from "./Title";
import { FETCH_SUCCESS } from "../../constants/Constants";
import { postApi } from "../../utils/api";
import Colors from "../../constants/Colors";

const useStyles = (theme) => ({
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  summaryText: {
    color: Colors.chartSummary,
  },
  chartTitleContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  chartContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  summaryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
});

class ListingPerformance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      viewsTotal: -1,
      viewsWow: -1,
      viewsComparison: [],
    };
  }
  setError(errorMsg) {
    this.setState({
      errorMessage: errorMsg,
      loading: false,
      viewsTotal: -1,
      viewsWow: -1,
      viewsComparison: [],
    });
  }
  handleData(data) {
    //console.log(data);
    let comparison = [];
    if (
      data != null &&
      data.listingViews != null &&
      data.bluenestAvgViews != null &&
      data.listingViews.length > 0
    ) {
      data.listingViews.forEach(function (value, i) {
        comparison.push({
          listingView: value,
          bluenestAvgView: data.bluenestAvgViews[i],
          week: "T - " + i,
        });
      });
    }
    this.setState({
      viewsTotal: data != null ? data.total : -1,
      viewsWow: data != null ? data.wow : -1,
      viewsComparison: comparison,
      loading: false,
    });
  }
  async fetchPerformance(listId) {
    try {
      const resp = await postApi("getPerformanceViews", {
        listingId: listId,
      });
      if (resp.status == FETCH_SUCCESS) {
        this.handleData(resp.data);
      } else {
        this.setError(
          resp != null && resp.errorText != null && resp.errorText != ""
            ? resp.errorText
            : "Unable to process request"
        );
      }
    } catch (ex) {
      this.setError("Unable to process request");
    }
    const { onLoaded } = this.props;
    onLoaded();
  }
  componentDidMount() {
    const { listingId } = this.props;
    this.fetchPerformance(listingId);
  }
  render() {
    const { classes } = this.props;
    const {
      loading,
      errorMessage,
      viewsTotal,
      viewsWow,
      viewsComparison,
    } = this.state;
    const renderColorfulLegendText = function (value, entry) {
      const { color } = entry;

      return <span style={{ color, fontSize: "1.1rem" }}>{value}</span>;
    };
    return (
      <React.Fragment>
        <Title>Listing Performance: Online Views</Title>
        {errorMessage ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{this.state.errorMessage}</strong>
          </Alert>
        ) : loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.listContainer}>
            {viewsTotal != null && viewsTotal > 0 ? (
              <React.Fragment>
                <div className={classes.summaryContainer}>
                  <Typography
                    variant="h6"
                    classes={{ root: classes.summaryText }}
                  >
                    Total Views <b>{viewsTotal}</b>
                  </Typography>
                  {viewsWow != null && viewsWow > 0 ? (
                    <Typography
                      variant="h6"
                      classes={{ root: classes.summaryText }}
                    >
                      &nbsp;(<b>+{viewsWow}</b> in last week)
                    </Typography>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                {viewsComparison != null && viewsComparison.length > 0 ? (
                  <React.Fragment>
                    <div className={classes.chartContainer}>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={viewsComparison}
                          margin={{
                            top: 5,
                            right: 5,
                            left: 5,
                            bottom: 5,
                          }}
                        >
                          <XAxis label="Weeks" tick={false} />
                          <YAxis
                            label={
                              <Text
                                x={-25}
                                y={0}
                                dx={50}
                                dy={150}
                                offset={0}
                                angle={-90}
                              >
                                Views
                              </Text>
                            }
                          />

                          <Legend
                            iconSize={22}
                            formatter={renderColorfulLegendText}
                            wrapperStyle={{
                              bottom: "5px",
                            }}
                            // margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                          />
                          <Line
                            type="monotone"
                            dot={false}
                            dataKey="listingView"
                            stroke={Colors.chartSummary}
                            name="Your Listing"
                            strokeWidth="2"
                          />
                          <Line
                            type="monotone"
                            dot={false}
                            dataKey="bluenestAvgView"
                            name="Bluenest Avg"
                            stroke={Colors.primary}
                            strokeWidth="2"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                    <div className={classes.chartTitleContainer}>
                      <Typography component="h5" variant="subtitle2">
                        Compared with other Bluenest listings over last{" "}
                        {viewsComparison.length} weeks
                      </Typography>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            ) : (
              <Alert severity="info" icon={false}>
                We are still in the process of gathering this data for you!
              </Alert>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
ListingPerformance.propTypes = {
  classes: PropTypes.object.isRequired,
  listingId: PropTypes.string,
  onLoaded: PropTypes.func,
};
export default withStyles(useStyles)(ListingPerformance);
