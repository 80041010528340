import PropTypes from "prop-types";

function ProcessGuideTitle({ guideType }) {
  var modalTitle = "HDB Sellers Guide";
  if (guideType == 1) modalTitle = "Private Sellers Guide";
  else if (guideType == 2) modalTitle = "HDB Rental Guide";
  else if (guideType == 3) modalTitle = "Private Rental Guide";
  return modalTitle;
}

ProcessGuideTitle.propTypes = {
  guideType: PropTypes.number.isRequired,
};

export default ProcessGuideTitle;
