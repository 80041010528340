import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

//import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";

import Colors from "../../constants/Colors";
import analytics from "../../utils/analytics";
import ProcessGuideTitle from "./ProcessGuideTitle";

const useStyles = makeStyles(() => ({
  drawerContainer: {
    background: Colors.background,
  },
}));

function Menu({
  guideType,
  activate,
  onMenuDeactivate,
  onGuideOpenRequest,
  onHomeRequest,
  referalUrl,
  feedbackUrl,
  listingTitle,
}) {
  const classes = useStyles();
  const handleGuideOpen = () => {
    analytics.sendEvent("Click", "Menu Guide", listingTitle, null);
    onGuideOpenRequest();
    onMenuDeactivate();
  };
  const handleHomeRequest = () => {
    onHomeRequest();
    onMenuDeactivate();
  };
  const onRefClick = () => {
    window.open(referalUrl, "_blank");
    analytics.sendEvent("Click", "Referral Section", listingTitle, null);
  };
  const onFeedbackClick = () => {
    window.open(feedbackUrl, "_blank");
    analytics.sendEvent("Click", "Feedback Section", listingTitle, null);
  };
  const modalTitle = <ProcessGuideTitle guideType={guideType} />;
  return (
    <div className={classes.drawerContainer}>
      <Drawer
        anchor="left"
        open={activate}
        onClose={onMenuDeactivate}
        classes={{ paper: classes.drawerContainer }}
      >
        <List>
          <ListItem button key="home" onClick={handleHomeRequest}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button key="guide" onClick={handleGuideOpen}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={modalTitle} />
          </ListItem>
          <ListItem button key="feedback" onClick={onFeedbackClick}>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="refer" onClick={onRefClick}>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Refer a friend, Win S$400" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

Menu.propTypes = {
  guideType: PropTypes.number.isRequired,
  activate: PropTypes.bool.isRequired,
  onMenuDeactivate: PropTypes.func.isRequired,
  onGuideOpenRequest: PropTypes.func.isRequired,
  onHomeRequest: PropTypes.func.isRequired,
  referalUrl: PropTypes.string,
  feedbackUrl: PropTypes.string,
  listingTitle: PropTypes.string,
};

export default Menu;
