import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Pagination } from "@material-ui/lab";
import Badge from "@material-ui/core/Badge";

import Title from "./Title";
import { FETCH_SUCCESS } from "../../constants/Constants";
import { postApi } from "../../utils/api";
import analytics from "../../utils/analytics";

const useStyles = (theme) => ({
  container: {
    width: "100%",
  },
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  listContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  pagingContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  item: {
    paddingBottom: 5,
    paddingLeft: 0,
  },
  normalFeedback: {},
  noFeedback: {
    color: "grey",
  },
  anchorOriginTopRightRectangle: {
    top: 0,
    right: 0,
    transform: "scale(1.5) translate(-20%, -90%)",
    transformOrigin: "100% 0%",
  },
});

class ViewingsFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      viewingsData: [],
      viewingsCount: 0,
      page: 1,
    };
  }
  setError(errorMsg) {
    this.setState({
      errorMessage: errorMsg,
      loading: false,
      viewingsData: [],
      viewingsCount: 0,
    });
  }
  handleData(data) {
    this.setState({
      viewingsData: data,
      viewingsCount: data == null ? 0 : data.length,
      loading: false,
    });
  }
  async fetchViewingsUpcoming(listId) {
    try {
      const resp = await postApi("getViewingsFeedback", {
        listingId: listId,
      });
      if (resp.status == FETCH_SUCCESS) {
        this.handleData(resp.data);
      } else {
        console.log(resp);
        this.setError(
          resp != null && resp.errorText != null && resp.errorText != ""
            ? resp.errorText
            : "Unable to process request"
        );
      }
    } catch (ex) {
      this.setError("Unable to process request");
    }
  }
  componentDidMount() {
    const { listingId } = this.props;
    this.fetchViewingsUpcoming(listingId);
  }
  setPage(val) {
    this.setState({ page: val });
    const { listingId } = this.props;
    analytics.sendEvent("Click", "Feedback Section", listingId, val);
  }
  render() {
    const { classes } = this.props;
    const {
      loading,
      viewingsCount,
      errorMessage,
      viewingsData,
      page,
    } = this.state;

    const pageNumber = page;
    const numberOfItemsForPage = 4;
    const slicearg1 = (pageNumber - 1) * numberOfItemsForPage;
    const slicearg2 =
      (pageNumber - 1) * numberOfItemsForPage + numberOfItemsForPage;
    const renViewings =
      viewingsData != null ? viewingsData.slice(slicearg1, slicearg2) : [];

    const paginationCount =
      viewingsCount % numberOfItemsForPage === 0
        ? viewingsCount / numberOfItemsForPage
        : Math.floor(viewingsCount / numberOfItemsForPage + 1);

    return (
      <Badge
        badgeContent={viewingsCount}
        //fullWidth
        color="primary"
        classes={{
          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
        }}
      >
        <div className={classes.container}>
          <Title>Completed Viewings</Title>
          <div className={classes.listContainer}>
            {errorMessage ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <strong>{this.state.errorMessage}</strong>
              </Alert>
            ) : loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <div className={classes.listContainer}>
                {viewingsData != null && viewingsData.length > 0 ? (
                  <React.Fragment>
                    <List dense={true} disablePadding={true}>
                      {renViewings.map((item) => (
                        <ListItem
                          key={`item-${item.id}`}
                          classes={{ root: classes.item }}
                          divider={true}
                        >
                          <ListItemText
                            primary={
                              <Grid container spacing={1}>
                                <Grid item xs={4} md={6}>
                                  <Typography component="span" variant="body1">
                                    {moment(item.dt).format("MMM Do")}
                                  </Typography>
                                </Grid>
                                {/* <Grid item xs={4} md={6}> 
                                  <Chip
                                    label={item.warmText}
                                    color="secondary"
                                    variant="outline"
                                    style={{
                                      borderColor: `${
                                        item.warm === "1"
                                          ? "#ef5350"
                                          : item.warm === "2"
                                          ? "orange"
                                          : "#42a5f5"
                                      }`,
                                      color: `${
                                        item.warm === "1"
                                          ? "#ef5350"
                                          : item.warm === "2"
                                          ? "orange"
                                          : "#42a5f5"
                                      }`,
                                    }}
                                  />
                                </Grid>*/}
                                <Grid item xs={12}>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    classes={{
                                      body2: `${
                                        item.status == 2
                                          ? classes.normalFeedback
                                          : classes.noFeedback
                                      }`,
                                    }}
                                  >
                                    {item.status == 2
                                      ? item.feedback
                                      : item.status == 1
                                      ? "Prospect not responding"
                                      : "Awaiting Feedback"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                    <div className={classes.pagingContainer}>
                      <Pagination
                        count={paginationCount}
                        color="primary"
                        page={page}
                        onChange={(event, val) => this.setPage(val)}
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <Alert severity="info" icon={false}>
                    No viewings completed at this time. Our agents are working
                    hard to line up prospects!
                  </Alert>
                )}
              </div>
            )}
          </div>
        </div>
      </Badge>
    );
  }
}
ViewingsFeedback.propTypes = {
  classes: PropTypes.object.isRequired,
  listingId: PropTypes.string,
};
export default withStyles(useStyles)(ViewingsFeedback);
