import React from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Footer from "./components/Footer";

export default function Error404() {
  return (
    <React.Fragment>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <strong>Ooops...we cannot find what you are looking for</strong>
      </Alert>
      <Footer />
    </React.Fragment>
  );
}
