import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function ProcessGuideContentPrivateSeller() {
  return (
    <React.Fragment>
      <Typography variant="body">
        Below guide breaks down your transaction journey into stages and tells
        you what to expect in each stage.
      </Typography>
      <br />
      <br />
      <Typography variant="h6">1. Listing Live</Typography>
      <Typography variant="body">
        Our team has already taken photos of your unit, prepared a listing and
        published it on property portals and social media networks. We will now
        communicate with eager buyers and arrange viewings for interested
        prospects.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Days to Months depending on demand for your unit
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Be as flexible with your schedule as possible to help conduct as
            many viewings as possible.
          </li>
          <li>Maintain a clean and presentable unit to wow buyers.</li>
          <li>
            Keep checking the customer portal for feedback from buyers, monitor
            demand for your unit and the competition.
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Our marketing team is using our AI powered tools to monitor
            performance of your listing as well as the competition on various
            platforms and optimizing to ensure you stay ahead of the
            competition.
          </li>
          <li>
            Our top agents are responding to prospects and putting their
            experience to work to help you get the best possible price.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h6">2. OTP Issue</Typography>
      <Typography variant="body">
        Congratulations! We have found a buyer and agreed on a price. You have
        issued an Option to Purchase (OTP) to the buyer and received 1% (or as
        stated in OTP agreement) of the purchase price an option fees. Buyers
        typically have 14 calendar days (unless stated otherwise in OTP
        agreement) to exercise the option. If the buyer backs out, you get to
        keep the Option Fee(unless stated otherwise in OTP agreement), but
        you’ll have to put your house back on the market.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Buyers are typically given 14 calendar days(or as stated in OTP
        agreement) to exercise option.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>Please reach out to our support team if you have any queries.</li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            We are communicating with buyer or buyer’s agent as needed to get
            the option exercised.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h6">3. OTP Exercised</Typography>
      <Typography variant="body">
        The buyer has opted to exercise the option given. You receive another
        4%(or as stated in OTP agreement) as the Exercise of Option Fee. Once
        the buyer exercises the OTP, you have a 14-day deadline to pay IRAS your
        Seller’s Stamp Duty (if you need to).
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        The transaction should close in approximately 10 to 12 weeks from OTP
        Exercised.
      </Typography>
      <br />
      <br />
      <br />
      <br />

      <Typography variant="h6">4. Inspection</Typography>
      <Typography variant="body">
        In the remaining time before the sale closes, you’ll have to move out.
        <br />
        <br />
        You’re still under obligation to maintain the place in the same
        condition, but other than that there shouldn’t be any more hurdles to
        jump over. <br />
        <br />
        Once you’ve moved all your stuff out, invite the buyer over to conduct a
        final inspection to confirm vacant possession before the close of the
        sale.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">Anytime before sales completion</Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">5. Sales Completion</Typography>
      <Typography variant="body">
        Sales will be completed at your lawyers office and this is when you will
        receive remaining 95%(or as stated in OTP agreement) of the sales
        amount. You will need to hand over the keys, sign documents, and get all
        the bank loan and CPF transactions sorted out.
        <br />
        <br />
        This is also when you’ll have to settle conveyancing fees, agent
        commissions, and bank early repayment/legal fees. The lawyers will
        settle the official transfer of the title deeds.
      </Typography>
      <br />
      <br />
    </React.Fragment>
  );
}

export default ProcessGuideContentPrivateSeller;
