import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import ProcessGuideTitle from "./ProcessGuideTitle";
import ProcessGuideContent from "./ProcessGuideContent";
import Colors from "../../constants/Colors";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    //backgroundColor: Colors.background,
    color: Colors.primary,
  },
  dialogContent: {
    backgroundColor: Colors.background,
    //color: "#FF0000",
  },
  dialogContentText: {
    //backgroundColor: Colors.background,
    color: "#000000",
  },
  dialogAction: {
    //backgroundColor: Colors.background,
  },
  paper: {
    /* backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "80%",
      height: "80%", */
  },
}));

function ProcessGuide({ guideType, activate, onGuideDeactivate }) {
  const classes = useStyles();
  const handleModalClose = () => {
    console.log("guide close requested");
    onGuideDeactivate();
  };
  /*   const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (activate) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [activate]); */

  return (
    <div>
      <Dialog
        open={activate}
        onClose={handleModalClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="lg"
        disableBackdropClick="true"
      >
        <DialogTitle
          id="scroll-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          <Typography component="h2" variant="h5">
            <ProcessGuideTitle guideType={guideType} />
          </Typography>
        </DialogTitle>
        <DialogContent
          dividers="true"
          classes={{ root: classes.dialogContent }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
            classes={{ root: classes.dialogContentText }}
          >
            <ProcessGuideContent guideType={guideType} />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogAction }}>
          <Button onClick={handleModalClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ProcessGuide.propTypes = {
  guideType: PropTypes.number.isRequired,
  activate: PropTypes.bool.isRequired,
  onGuideDeactivate: PropTypes.func.isRequired,
};

export default ProcessGuide;
