import green from "@material-ui/core/colors/green";

const primary = "#0872BA";
const secondary = "#27387a";
const tertiary = green[300];
const chartSummary = green[800];
const background = "#ebedef";

export default {
  primary,
  secondary,
  tertiary,
  chartSummary,
  background,
};
