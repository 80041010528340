import React from "react";
import PropTypes from "prop-types";

import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Pagination } from "@material-ui/lab";

import Title from "./Title";
import { FETCH_SUCCESS } from "../../constants/Constants";
import { postApi } from "../../utils/api";
import analytics from "../../utils/analytics";

const useStyles = (theme) => ({
  container: {
    width: "100%",
  },
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  pagingContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  anchorOriginTopRightRectangle: {
    top: 0,
    right: 0,
    transform: "scale(1.5) translate(-20%, -90%)",
    transformOrigin: "100% 0%",
  },
});

class CompetitorListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      competitionData: [],
      page: 1,
      competitorCount: 0,
    };
  }
  setPage(val) {
    this.setState({ page: val });
    const { listingId } = this.props;
    analytics.sendEvent("Click", "Competitor Listing Section", listingId, val);
    console.log("page change");
  }
  setError(errorMsg) {
    this.setState({
      errorMessage: errorMsg,
      loading: false,
      competitionData: [],
    });
  }
  handleData(data) {
    this.setState({
      competitionData: data,
      competitorCount: data == null ? 0 : data.length,
      loading: false,
    });
  }
  async fetchCompetition(listId) {
    try {
      const resp = await postApi("getCompetitionListings", {
        listingId: listId,
      });
      if (resp.status == FETCH_SUCCESS) {
        this.handleData(resp.data);
      } else {
        this.setError(
          resp != null && resp.errorText != null && resp.errorText != ""
            ? resp.errorText
            : "Unable to process request"
        );
      }
    } catch (ex) {
      this.setError("Unable to process request");
    }
    const { onLoaded } = this.props;
    onLoaded();
  }
  componentDidMount() {
    const { listingId } = this.props;
    this.fetchCompetition(listingId);
  }
  render() {
    const { classes } = this.props;
    const {
      loading,
      errorMessage,
      competitionData,
      page,
      competitorCount,
    } = this.state;
    const pageNumber = page;
    const numberOfItemsForPage = 5;
    const slicearg1 = (pageNumber - 1) * numberOfItemsForPage;
    const slicearg2 =
      (pageNumber - 1) * numberOfItemsForPage + numberOfItemsForPage;
    const renCompData =
      competitionData != null
        ? competitionData.slice(slicearg1, slicearg2)
        : [];
    var compeititorCount = competitionData != null ? competitionData.length : 0;
    const paginationCount =
      compeititorCount % numberOfItemsForPage === 0
        ? compeititorCount / numberOfItemsForPage
        : Math.floor(compeititorCount / numberOfItemsForPage + 1);
    return (
      <Badge
        badgeContent={competitorCount}
        //fullWidth
        color="primary"
        max={999}
        classes={{
          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
        }}
      >
        <div className={classes.container}>
          <Title>Competitor Listings</Title>
          {errorMessage ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          ) : loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.listContainer}>
              {competitionData != null && competitionData.length > 0 ? (
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      //className={classes.table}
                      size="small"
                      //fixedHeader={false}
                      style={{
                        width: "auto",
                        tableLayout: "auto",
                        minWidth: "700px",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              fontWeight: "bold",
                              minWidth: "300",
                              maxWidth: "300",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "75",
                              maxWidth: "100",
                            }}
                          >
                            Price&nbsp;($)
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "50",
                              maxWidth: "100",
                            }}
                          >
                            Area&nbsp;(sqft)
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "50",
                              maxWidth: "100",
                            }}
                          >
                            PSF&nbsp;($/sqft)
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "50",
                              maxWidth: "100",
                            }}
                          >
                            Rooms
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "50",
                              maxWidth: "100",
                            }}
                          >
                            Distance&nbsp;(m)
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              fontWeight: "bold",
                              minWidth: "75",
                              maxWidth: "100",
                            }}
                          >
                            Active For
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {renCompData.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell
                              style={{
                                minWidth: "300",
                                maxWidth: "300",
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.address != "" ? row.address : "Not Provided"}
                            </TableCell>
                            <TableCell
                              numberic
                              align="right"
                              style={{
                                minWidth: "75",
                                maxWidth: "100",
                              }}
                            >
                              {row.price.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                              })}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                minWidth: "50",
                                maxWidth: "100",
                              }}
                            >
                              {row.area.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                              })}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                minWidth: "50",
                                maxWidth: "100",
                              }}
                            >
                              {row.psf.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                              })}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                minWidth: "50",
                                maxWidth: "100",
                              }}
                            >
                              {row.rooms}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                minWidth: "50",
                                maxWidth: "100",
                              }}
                            >
                              {row.distance}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                minWidth: "75",
                                maxWidth: "100",
                              }}
                            >
                              {row.activeForDays}&nbsp;days
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.pagingContainer}>
                    <Pagination
                      count={paginationCount}
                      color="primary"
                      siblingCount={0}
                      page={page}
                      onChange={(event, val) => this.setPage(val)}
                    />
                  </div>
                </div>
              ) : (
                <Alert severity="info" icon={false}>
                  Information about listings that compete with yours is not
                  currently available. We are working on it!
                </Alert>
              )}
            </div>
          )}
        </div>
      </Badge>
    );
  }
}
CompetitorListings.propTypes = {
  classes: PropTypes.object.isRequired,
  listingId: PropTypes.string,
  onLoaded: PropTypes.func,
};
export default withStyles(useStyles)(CompetitorListings);
