import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import emoji from "emoji-dictionary";
import analytics from "../../utils/analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
  },
  logoContainer: {
    paddingBottom: theme.spacing(1),
  },
  referContainer: {
    position: "relative",
    maxWidth: "600px",
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    marginBottom: theme.spacing(3),
    backgroundColor: "#ffffff",
  },
  referArrow: {
    position: "absolute",
    right: "15%",
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
    },
    top: "30%",
  },
  referTCContainer: {
    paddingTop: theme.spacing(3),
  },
}));

function Footer(props) {
  const classes = useStyles();
  const em = emoji.getUnicode("tada");
  const refUrl = props.referalUrl;
  const displayRef = props.refLine1 || props.refLine2;
  const onRefClick = function () {
    analytics.sendEvent("Click", "Referral Section", props.listingTitle, null);
  };

  return (
    <div className={classes.container}>
      {displayRef ? (
        <Link
          color="inherit"
          href={refUrl}
          target="_blank"
          onClick={onRefClick}
        >
          <div className={classes.referContainer}>
            <Typography variant="h6" color="primary" align="center">
              {em} {props.refLine1} {em} <br />
              {props.refLine2}
            </Typography>
            <div className={classes.referTCContainer}>
              <Typography variant="body2" color="primary" align="center">
                T&C apply
              </Typography>
            </div>
            <div className={classes.referArrow}>
              <Typography variant="h5" color="primary" align="center">
                &#8594;
              </Typography>
            </div>
          </div>
        </Link>
      ) : (
        <React.Fragment />
      )}
      <div className={classes.logoContainer}>
        <img
          width="150px"
          src={window.location.origin + "/Bluenest_su_5a.png"}
        />
      </div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://bluenest.sg/" target="_blank">
          Bluenest
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

Footer.propTypes = {
  referalUrl: PropTypes.string,
  refLine1: PropTypes.string,
  refLine2: PropTypes.string,
  listingTitle: PropTypes.string,
};

export default Footer;
