import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";

import Title from "./Title";
import { FETCH_SUCCESS } from "../../constants/Constants";
import { postApi } from "../../utils/api";

const useStyles = (theme) => ({
  container: {
    width: "100%",
  },
  loadingContainer: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  listContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: 0,
  },
  anchorOriginTopRightRectangle: {
    top: 0,
    right: 0,
    transform: "scale(1.5) translate(-20%, -90%)",
    transformOrigin: "100% 0%",
  },
  viewingContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

class ViewingsUpcoming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      viewingsData: [],
      viewingsCount: 0,
    };
  }
  setError(errorMsg) {
    this.setState({
      errorMessage: errorMsg,
      loading: false,
      viewingsData: [],
      viewingsCount: 0,
    });
  }
  handleData(data) {
    this.setState({
      viewingsData: data,
      viewingsCount: data == null ? 0 : data.length,
      loading: false,
    });
  }
  async fetchViewingsUpcoming(listId) {
    try {
      const resp = await postApi("getViewingsUpcoming", {
        listingId: listId,
      });
      if (resp.status == FETCH_SUCCESS) {
        this.handleData(resp.data);
      } else {
        console.log(resp);
        this.setError(
          resp != null && resp.errorText != null && resp.errorText != ""
            ? resp.errorText
            : "Unable to process request"
        );
      }
    } catch (ex) {
      this.setError("Unable to process request");
    }
    const { onLoaded } = this.props;
    onLoaded();
  }
  componentDidMount() {
    const { listingId } = this.props;
    this.fetchViewingsUpcoming(listingId);
  }
  render() {
    const { classes } = this.props;
    const { loading, viewingsCount, errorMessage, viewingsData } = this.state;

    return (
      <Badge
        badgeContent={viewingsCount}
        //fullWidth
        color="primary"
        classes={{
          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
        }}
      >
        <div className={classes.container}>
          <Title>Upcoming Viewings</Title>
          {errorMessage ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          ) : loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.listContainer}>
              {viewingsData != null && viewingsData.length > 0 ? (
                viewingsData.map((d) => (
                  <React.Fragment key={d.id}>
                    <div className={classes.viewingContainer}>
                      <Typography
                        component="h5"
                        variant="subtitle1"
                        style={{ display: "inline-block" }}
                      >
                        {d.displayDt}
                      </Typography>
                      <Typography
                        component="h5"
                        variant="subtitle2"
                        color="textSecondary"
                      >
                        &nbsp;&nbsp;({d.numProspects.toString()} prospects)
                      </Typography>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <Alert severity="info" icon={false}>
                  No scheduled viewings at this time. Our agents are working
                  hard to line up prospects!
                </Alert>
              )}
            </div>
          )}
        </div>
      </Badge>
    );
  }
}
ViewingsUpcoming.propTypes = {
  classes: PropTypes.object.isRequired,
  listingId: PropTypes.string,
  onLoaded: PropTypes.func,
};
export default withStyles(useStyles)(ViewingsUpcoming);
