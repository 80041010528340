import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import Backdrop from "@material-ui/core/Backdrop";
//import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
//import Grow from "@material-ui/core/Grow";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";

import queryString from "query-string";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Menu from "./components/Menu";
import ProcessGuide from "./components/ProcessGuide";
import ViewingsUpcoming from "./components/ViewingsUpcoming";
import ViewingsFeedback from "./components/ViewingsFeedback";
import CompetitorListings from "./components/CompetitorListings";
import CompetitorSummary from "./components/CompetitorSummary";
import ListingPerformance from "./components/ListingPerformance";
import ProcessGuideTitle from "./components/ProcessGuideTitle";
import ProcessGuideContent from "./components/ProcessGuideContent";
import { FETCH_SUCCESS } from "../constants/Constants";
import { postApi } from "../utils/api";
import analytics from "../utils/analytics";
import { SURVEY_BASE_URL } from "../constants/Constants";

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  main: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    padding: 0,
    background: "#ebedef",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "visible",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "calc(100% - 30px)",
  },
  statusContainer: {
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  badge1: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
  badge: {
    marginRight: 20,
  },
  footerContainer: {},
  chipLabel: {
    fontSize: 16,
  },
  chipIcon: {
    position: "absolute",
    right: 10,
  },
  chipRoot: {
    width: 160,
    position: "relative",
  },
  hideMe: {
    display: "none",
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      listingTitle: "",
      refEmail: "",
      refLine1: "",
      refLine2: "",
      forceSurvey1: false,
      animate1: false,
      animate2: false,
      animate3: false,
      animate4: false,
      showMenu: false,
      showModalGuide: false,
      listingType: 0,
      showingNow: 0,
      isDashboardEnabled: false,
    };
  }
  onSectionLoaded = (section) => () => {
    if (section == "section4") {
      //   this.animatePages();
    }
  };
  homeRequested = () => {
    if (this.state.isDashboardEnabled) {
      this.setState({ showingNow: 0 });
    } else {
      this.setState({ showingNow: 1 });
    }
  };
  guideRequested = () => {
    //this.setState({ showModalGuide: true });
    this.setState({ showingNow: 1 });
  };
  guideModalRequested = () => {
    this.setState({ showModalGuide: true });
    const { listingTitle } = this.state;
    analytics.sendEvent("Click", "Stage Guide", listingTitle, null);
  };
  guideModalClosed = () => {
    this.setState({ showModalGuide: false });
  };
  menuRequested = () => {
    this.setState({ showMenu: true });
  };
  menuClosed = () => {
    this.setState({ showMenu: false });
  };
  handleStatusClick = () => {
    this.guideModalRequested();
  };
  animatePages() {
    setTimeout(() => {
      this.setState({ animate1: true });
    }, 1000);
    setTimeout(() => {
      this.setState({ animate2: true });
    }, 1250);
    setTimeout(() => {
      this.setState({ animate3: true });
    }, 1500);
    setTimeout(() => {
      this.setState({ animate4: true });
    }, 1750);
  }
  setError(errorMsg) {
    this.setState({ errorMessage: errorMsg, loading: false });
  }
  handleData(data) {
    this.setState({
      listingTitle: data.title,
      listingType: data.listingType,
      loading: false,
      refEmail: data.referalEmail,
      refLine1: data.referalLine1,
      refLine2: data.referalLine2,
      forceSurvey1: data.forceSurvey1,
      isDashboardEnabled: data.isDashboardEnabled,
    });
    analytics.sendEvent("Visit", data.title, this.getListingParam(), 0);
    //redirect if needed
    if (data.forceSurvey1) {
      this.redirectToSurveyPage();
    }
    //this.animatePages();
  }
  redirectToSurveyPage() {
    try {
      if (!window.location.href.includes("localhost")) {
        let url = this.props.location.search;
        if (url != null) {
          let params = queryString.parse(url);
          if (params.fromSurvey == null) {
            const listingId = this.getListingParam();
            const { listingTitle } = this.state;
            window.location.replace(
              SURVEY_BASE_URL +
                "?listingId=" +
                encodeURIComponent(listingId) +
                "&title=" +
                encodeURIComponent(listingTitle)
            );
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
  async fetchListingSummary(listId) {
    const resp = await postApi("getSummary", { listingId: listId });
    if (resp.status == FETCH_SUCCESS) {
      if (resp.data.isEnabled) {
        this.handleData(resp.data);
        this.homeRequested();
      } else {
        this.setError(
          "Sorry this service is currently not available for your listing"
        );
      }
    } else {
      this.setError(
        resp.errorText == "" ? "Unexpected Error Occured" : resp.errorText
      );
    }
  }
  getListingParam() {
    const { match } = this.props;
    if (
      match.params != null &&
      match.params.listingId != null &&
      match.params.listingId.length < 36 &&
      match.params.listingId != "demo"
    ) {
      return "";
    } else {
      return match.params.listingId == "demo"
        ? "00000000-0000-0000-0000-000000000001"
        : match.params.listingId;
    }
  }
  componentDidMount() {
    const listingId = this.getListingParam();
    if (listingId == "") {
      this.setError("Ooops...URL specified is not valid");
    } else {
      this.fetchListingSummary(listingId);
    }
  }

  render() {
    const { classes } = this.props;
    const listingId = this.getListingParam();
    const {
      refEmail,
      refLine1,
      refLine2,
      listingTitle,
      listingType,
      loading,
      showMenu,
      showModalGuide,
      showingNow,
    } = this.state;
    const refUrl = refEmail
      ? "https://www.bluenest.sg/refer-your-friend?email=" + refEmail
      : "https://www.bluenest.sg/refer-your-friend";
    const feedbackUrl = refEmail
      ? "https://www.bluenest.sg/feedback?email=" + refEmail
      : "https://www.bluenest.sg/feedback";
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const pageTitle =
      showingNow == 0 ? (
        listingTitle
      ) : (
        <ProcessGuideTitle guideType={listingType} />
      );
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Header titleText={pageTitle} onMenuRequest={this.menuRequested} />
        <div className={classes.main}>
          {this.state.errorMessage ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{this.state.errorMessage}</strong>
            </Alert>
          ) : showingNow == 0 ? (
            <React.Fragment>
              <Backdrop className={classes.backdrop} open={loading}>
                {/* <CircularProgress color="inherit" /> */}
              </Backdrop>
              <div align="center" className={classes.statusContainer}>
                <Chip
                  label="Listing Live"
                  color="secondary"
                  icon={<HelpIcon fontSize="small" />}
                  onClick={this.handleStatusClick}
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                    icon: classes.chipIcon,
                  }}
                />
              </div>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={6}>
                  {/* <Grow in={animate1}> */}
                  <Paper className={fixedHeightPaper}>
                    <ViewingsUpcoming
                      listingId={listingId}
                      onLoaded={this.onSectionLoaded("section1")}
                    />
                  </Paper>
                  {/* </Grow> */}
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={6}>
                  {/* <Grow in={animate2}> */}
                  <Paper className={classes.paper}>
                    <ViewingsFeedback listingId={listingId} />
                  </Paper>
                  {/* </Grow> */}
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12} md={6}>
                  {/* <Grow in={animate3}> */}
                  <div className={classes.hideMe}>
                    <Paper className={fixedHeightPaper}>
                      <CompetitorSummary
                        listingId={listingId}
                        onLoaded={this.onSectionLoaded("section3")}
                      />
                    </Paper>
                  </div>
                  {/* </Grow> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <Grow in={animate4}> */}
                  <div className={classes.hideMe}>
                    <Paper className={classes.paper}>
                      <CompetitorListings
                        listingId={listingId}
                        onLoaded={this.onSectionLoaded("section4")}
                      />
                    </Paper>
                    {/* </Grow> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <Grow in={animate4}> */}
                  <Paper className={classes.paper}>
                    <ListingPerformance
                      listingId={listingId}
                      onLoaded={this.onSectionLoaded("section4")}
                    />
                  </Paper>
                  {/* </Grow> */}
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ProcessGuideContent guideType={listingType} />
            </React.Fragment>
          )}
        </div>
        <div className={classes.footerContainer}>
          <Footer
            referalUrl={refUrl}
            refLine1={refLine1}
            refLine2={refLine2}
            listingTitle={listingTitle}
          />
        </div>
        <div>
          <Menu
            activate={showMenu}
            onMenuDeactivate={this.menuClosed}
            onGuideOpenRequest={this.guideRequested}
            onHomeRequest={this.homeRequested}
            guideType={listingType}
            referalUrl={refUrl}
            feedbackUrl={feedbackUrl}
            listingTitle={listingTitle}
          ></Menu>
          <ProcessGuide
            activate={showModalGuide}
            onGuideDeactivate={this.guideModalClosed}
            guideType={listingType}
          ></ProcessGuide>
        </div>
      </Container>
    );
  }
}
Home.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Home);
