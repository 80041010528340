import React from "react";
import PropTypes from "prop-types";

import ProcessGuideContentHDBSeller from "./ProcessGuideContentHDBSeller";
import ProcessGuideContentHDBRental from "./ProcessGuideContentHDBRental";
import ProcessGuideContentPrivateSeller from "./ProcessGuideContentPrivateSeller";
import ProcessGuideContentPrivateRental from "./ProcessGuideContentPrivateRental";

function ProcessGuideContent({ guideType }) {
  if (guideType == 1) return <ProcessGuideContentPrivateSeller />;
  else if (guideType == 2) return <ProcessGuideContentHDBRental />;
  else if (guideType == 3) return <ProcessGuideContentPrivateRental />;
  else return <ProcessGuideContentHDBSeller />;
}

ProcessGuideContent.propTypes = {
  guideType: PropTypes.number.isRequired,
};

export default ProcessGuideContent;
