import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function ProcessGuideContentHDBSeller() {
  return (
    <React.Fragment>
      <Typography variant="body">
        Below guide breaks down your transaction journey into stages and tells
        you what to expect in each stage.
      </Typography>
      <br />
      <br />
      <Typography variant="h6">1. Listing Live</Typography>
      <Typography variant="body">
        Our team has already taken photos of your unit, prepared a listing and
        published it on property portals and social media networks. We will now
        communicate with eager buyers and arrange viewings for interested
        prospects.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Days to Months depending on demand for your unit
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Register intent to sell on HDB resale portal. An Option To Purchase
            (OTP) can only be issued to an interested buyer 7 days after this is
            done.
          </li>
          <li>
            Be as flexible with your schedule as possible to help conduct as
            many viewings as possible.
          </li>
          <li>Maintain a clean and presentable unit to wow buyers.</li>
          <li>
            Keep checking the customer portal for feedback from buyers, monitor
            demand for your unit and the competition.
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Our marketing team is using our AI powered tools to monitor
            performance of your listing as well as the competition on various
            platforms and optimizing to ensure you stay ahead of the
            competition.
          </li>
          <li>
            Our top agents are responding to prospects and putting their
            experience to work to help you get the best possible price.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h6">2. OTP Issue &amp; Exercise</Typography>
      <Typography variant="body">
        Congratulations! We have found a buyer and agreed on a price. You have
        issued an Option to Purchase (OTP) to the buyer and received an option
        fees for this as per the OTP agreement. Buyers have 21 calendar days
        (unless stated otherwise in OTP agreement) to exercise the option.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Buyers are given 21 calendar days to exercise option.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>Please reach out to our support team if you have any queries.</li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            We are communicating with buyer or buyer’s agent as needed to get
            the option exercised.
          </li>
        </ul>
      </Typography>
      <br />
      <br />
      <Typography variant="h6">3. HDB Application &amp; Acceptance</Typography>
      <Typography variant="body">
        Great, the buyer has exercised the Option To Purchase (OTP) that you
        granted him. Buyer and you(seller) need to make a resale application to
        HDB. You should receive a notification via email/SMS when the
        application has been accepted by HDB. HDB will also indicate the
        estimated completion date and inspection date and timing by an HDB
        officer.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        HDB will take around 10 days to review and accept resale application
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What do I need to do?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Bluenest team will work with you to submit a resale application to
            HDB.
          </li>
        </ul>
      </Typography>
      <Typography variant="subtitle1">
        <Box fontStyle="italic">What is Bluenest doing for me?</Box>
      </Typography>
      <Typography variant="body">
        <ul>
          <li>
            Bluenest will communicate with buyer or buyer’s agent to co-ordinate
            submission of application by buyers.
          </li>
        </ul>
      </Typography>
      <br />
      <br />

      <Typography variant="h6">4. HDB Inspection</Typography>
      <Typography variant="body">
        The purpose of the inspection by the HDB Branch is to check for
        unauthorised renovation works in the resale flat which may damage the
        common property or other flats in the building and affect the structural
        stability of the building. The sellers must regularise/remove any
        unauthorised renovation works found in the resale flat before completion
        of the resale transaction.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">Anytime before sales completion</Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">5. Documents Endorsed</Typography>
      <Typography variant="body">
        You (seller) needs to login to resale portal to endorse the documents
        online to confirm the sales price, seller particulars and financial
        calculation breakdown. Bluenest team is happy to guide you through the
        process.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        Within 3 weeks(approximately) of HDB acceptance.
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">6. HDB Approval</Typography>
      <Typography variant="body">
        You (seller) needs to use HDB Resale Portal to go to Step 5 -&gt;
        Details-&gt; Confirmation from Town Council of service and conservancy
        charges. Print out the form and bring it to town council to make payment
        for service and conservancy charges via cash and get the form endorsed
        by Town council.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">
        About 1 week after Documents Endorsement
      </Typography>
      <br />
      <br />
      <br />
      <br />
      <Typography variant="h6">7. Sales Completion</Typography>
      <Typography variant="body">
        A resale transaction takes about 8 weeks (from the date of HDB’s
        acceptance of the resale application) to complete. Both you and buyers
        will be informed of the completion date in the completion letter, and to
        attend a completion appointment at HDB Hub. Please bring your invoice
        from town council where payment was made and windows declaration form
        (link to windows declaration form).
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle1">
        <Box fontStyle="italic">Timeline</Box>
      </Typography>
      <Typography variant="body">About 8 weeks from HDB acceptance</Typography>
      <br />
      <br />
    </React.Fragment>
  );
}

export default ProcessGuideContentHDBSeller;
